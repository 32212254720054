import React, { FC, lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import AuthenticationLayout from '../layouts/Authentication';
import DefaultLayout from '../layouts/Default';
import Route from './Route';

const Dashboard = lazy(() => import('../pages/admin/Dashboard'));
const Profile = lazy(() => import('../pages/admin/Profile'));
const Support = lazy(() => import('../pages/admin/Support'));
const User = lazy(() => import('../pages/admin/User'));
const ViewUser = lazy(() => import('../pages/admin/User/ViewUser'));
const CreateUser = lazy(() => import('../pages/admin/User/CreateUser'));
const SignIn = lazy(() => import('../pages/auth/SignIn'));
const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));
const Payment = lazy(() => import('../pages/admin/Payment'));
const Meliponario = lazy(() => import('../pages/admin/Meliponario'));
const Colony = lazy(() => import('../pages/admin/Colony'));
const TechnicalSupport = lazy(() => import('../pages/admin/TechnicalSupport'));
const ManagementDetails = lazy(
  () => import('../pages/admin/Management/Details'),
);
const Recommendation = lazy(
  () => import('../pages/admin/TechnicalSupport/Recommendation'),
);
const Message = lazy(() => import('../pages/admin/Message'));
const RegisterPayment = lazy(
  () => import('../pages/admin/Payment/RegisterPayment'),
);
const UpdatePayment = lazy(
  () => import('../pages/admin/Payment/UpdatePayment'),
);
const Page404 = lazy(() => import('../pages/NotFound'));

const AppNavigation: FC = () => {
  return (
    <>
      <Suspense fallback={<LinearProgress color="primary" />}>
        <Switch>
          <Route
            path="/"
            exact
            component={SignIn}
            template={AuthenticationLayout}
          />
          <Route
            path="/login"
            exact
            component={SignIn}
            template={AuthenticationLayout}
          />
          <Route
            path="/forgot-password"
            exact
            component={ForgotPassword}
            template={AuthenticationLayout}
          />
          <Route
            path="/reset-password/:token"
            exact
            component={ResetPassword}
            template={AuthenticationLayout}
          />

          <Route
            path="/admin"
            exact
            isPrivate
            component={Dashboard}
            template={DefaultLayout}
          />
          <Route
            path="/admin/dashboard"
            exact
            isPrivate
            component={Dashboard}
            template={DefaultLayout}
          />
          <Route
            path="/admin/profile"
            exact
            isPrivate
            component={Profile}
            template={DefaultLayout}
          />
          {/* USERS */}
          <Route
            path="/admin/users/register"
            exact
            isPrivate
            component={CreateUser}
            template={DefaultLayout}
          />
          <Route
            path="/admin/users/:userId/:edit?"
            exact
            isPrivate
            component={ViewUser}
            template={DefaultLayout}
          />
          <Route
            path="/admin/users"
            exact
            isPrivate
            component={User}
            template={DefaultLayout}
          />

          {/* PAYMENTS */}
          <Route
            path="/admin/payments"
            exact
            isPrivate
            component={Payment}
            template={DefaultLayout}
          />
          <Route
            path="/admin/payments/update/:paymentId"
            exact
            isPrivate
            component={UpdatePayment}
            template={DefaultLayout}
          />
          <Route
            path="/admin/payments/register"
            exact
            isPrivate
            component={RegisterPayment}
            template={DefaultLayout}
          />

          {/* MELIPONARIOS */}
          <Route
            path="/admin/meliponarios"
            exact
            isPrivate
            component={Meliponario}
            template={DefaultLayout}
          />

          {/* COLONIES */}
          <Route
            path="/admin/colonies"
            exact
            isPrivate
            component={Colony}
            template={DefaultLayout}
          />

          <Route
            path="/admin/support"
            exact
            isPrivate
            component={Support}
            template={DefaultLayout}
          />

          {/* MESSAGES */}
          <Route
            path="/admin/messages"
            exact
            isPrivate
            component={Message}
            template={DefaultLayout}
          />

          {/* MESSAGES */}
          <Route
            path="/admin/technical-supports/:id"
            exact
            isPrivate
            component={Recommendation}
            template={DefaultLayout}
          />
          <Route
            path="/admin/technical-supports"
            exact
            isPrivate
            component={TechnicalSupport}
            template={DefaultLayout}
          />

          {/* MANAGEMENTS */}
          <Route
            path="/admin/managements/details/:colonyId"
            exact
            isPrivate
            component={ManagementDetails}
            template={DefaultLayout}
          />

          {/* 404 */}
          <Route
            path="/*"
            isPublic
            component={Page404}
            template={AuthenticationLayout}
          />
        </Switch>
      </Suspense>
    </>
  );
};

export default AppNavigation;
