import { ptBR } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme(
  {
    palette: {
      primary: {
        '100': '#f3d371',
        main: '#f39c12',
        dark: '#d07609',
        contrastText: '#f8f9fa',
      },
      secondary: {
        main: '#333333',
        contrastText: '#fff',
      },
      error: {
        main: '#F83539',
      },
      text: {
        primary: '#140c04',
        secondary: '#d07609',
      },
      info: {
        main: '#f8f9fa',
        light: '#f2ecd9',
        '200': '#f2ecd9',
      },
    },
    typography: {
      h1: {
        fontSize: 96,
      },
      h2: {
        fontSize: 60,
      },
      h3: {
        fontSize: 48,
      },
      h4: {
        fontSize: 34,
      },
      h5: {
        fontSize: 24,
      },
      h6: {
        fontSize: 20,
      },
      subtitle1: {
        fontSize: 16,
      },
      subtitle2: {
        fontSize: 14,
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 14,
      },
      caption: {
        fontSize: 12,
      },
      overline: {
        fontSize: 10,
      },
    },
    overrides: {},
  },
  ptBR,
);
