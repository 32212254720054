/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { Button, Snackbar } from '@material-ui/core';
import axios from 'axios';

import { useAuth } from '../hooks/auth';
import { useCustomSnackbar } from '../hooks/snackbar';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  template: React.ComponentType;
  isPublic?: boolean;
}

type Location = {
  hash: string;
  pathname: string;
  search: string;
  state: {
    from: {
      hash: string;
      pathname: string;
      search: string;
    };
  };
};

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  template: Template,
  isPublic = false,
  ...rest
}) => {
  const { user, signOut, refresh } = useAuth();
  const {addSnackbar} = useCustomSnackbar();
  const getLocation = useLocation<Location>();
  const [expireToken, setExpireToke] = useState(false);
  // console.log(getLocation);
  // @ts-ignore
  const { from } = getLocation?.state || {
    from: { pathname: '/admin/dashboard' },
  };

  useEffect(() => {
    const interceptors = () => {
      axios.interceptors.request.use(
        (request) => {
          const expires_token = localStorage.getItem('@app:expires_token');
          if (expires_token) {
            const expiresDate = new Date(JSON.parse(expires_token));
            const today = new Date();
            const compareDate = new Date();
            compareDate.setMinutes(compareDate.getMinutes() + 1);

            if (compareDate > expiresDate && today <= expiresDate && user?.id) {
              setExpireToke(true);
            }
          }
          return request;
        },
        (error) => {
          return Promise.reject(error);
        },
      );

    axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
      console.log(err);
      if ((err.response?.status === 401 || err.response?.data?.message === 'Sua sessão expirou') && user?.id ) {
        setExpireToke(false);
        addSnackbar({
          title: 'Faça login novamente.',
          variant: 'error'
        });
        signOut();
      }
      return Promise.reject(err);
    });
    };

    if (user?.id) {
      interceptors();
    }
  }, [user, signOut, addSnackbar]);

  const handleClose = useCallback(() => {
    setExpireToke(false);
    signOut();
  }, [signOut]);

  const handleRefreshToken = useCallback(() => {
    setExpireToke(false);
    refresh();
  }, [refresh]);

  const ExpireToken = () => (
    <Snackbar
      key="sessão expirada"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={expireToken}
      autoHideDuration={6000}
      message="Sua sessão está quase expirando. Deseja continuar logado?"
      action={(
        <>
          <Button color="primary" size="small" onClick={handleRefreshToken}>
            Continuar
          </Button>
          <Button color="primary" size="small" onClick={handleClose}>
            Não
          </Button>
        </>
      )}
    />
  );

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user || isPublic ? (
          <Template>
            <Component />
            {expireToken && (
              <ExpireToken />
            )}
          </Template>
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : from.pathname,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
