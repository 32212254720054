import { makeStyles, Theme } from '@material-ui/core';

import bgColmeiaImg from '../../assets/colmeia-2-invertida.png';
import bgImg from '../../assets/manganga-bg-header.jpg';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    width: '100%',
    paddingRight: 24, // keep right padding when drawer closed
    [theme.breakpoints.down('sm')]: {
      paddingRight: 16,
    },
  },
  toolbarContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: theme.spacing(160),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    backgroundImage: `url(${bgImg}), linear-gradient(#f39c12, #d07609)`,
    backgroundSize: '100% auto',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
    },
  },
  toolbarImage: {
    height: '100%',
    maxHeight: '55px',
    marginLeft: 'auto',
    borderRadius: 4,
    padding: '4px 10px',
    backgroundColor: '#ffe498b8',
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(7)}px)`,
      marginLeft: theme.spacing(7),
    },
  },
  menuButton: {
    width: 72,
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: 4,
      width: 'auto',
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  menuSelected: {
    position: 'relative',
  },
  menuBoxSelected: {
    position: 'absolute',
    display: 'block',
    right: 0,
    top: 0,
    width: 2,
    height: '100%',
    background: '#f39c12',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.info.main,
  },
  drawerPaper: {
    backgroundImage: `url(${bgColmeiaImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%',
    backgroundPosition: 'bottom left',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(7),
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 'calc(100% - 65px)',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
    maxWidth: '80%',
  },
  version: {
    fontSize: '15px',
    fontWeight: 600,
  },
  listPopover: {},
  buttonAvatar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 0,
    color: theme.palette.info.main,
  },
  avatar: {
    height: 30,
    width: 30,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  profile: {
    textTransform: 'capitalize',
    marginLeft: 8,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
