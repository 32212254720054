import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Avatar,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  useMediaQuery,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import clsx from 'clsx';

import logoImg from '../../assets/logo.png';
import { Footer } from '../../components/Footer';
// import { Notification } from '../../components/Notification';
import config from '../../config/environmentConfig';
import menu from '../../config/menuConfig';
import { useAuth } from '../../hooks/auth';
import { useStyles } from './styles';

const Default: FC = ({ children }) => {
  const matches = useMediaQuery('(min-width:600px)');
  const classes = useStyles();
  const primaryMenus = menu.getPrimaryMenus();
  const secondMenus = menu.getSecondMenus();
  const appConfig = useMemo(() => {
    return {
      version: config.getEnv('REACT_APP_VERSION'),
      appName: config.getEnv('REACT_APP_NAME'),
      appFullName: config.getEnv('REACT_APP_FULL_NAME'),
    };
  }, []);

  const { location } = useHistory();
  const menuSelected = useMemo(() => {
    return menu.getSelected(location.pathname);
  }, [location.pathname]);

  const { signOut, user } = useAuth();
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    setOpen(matches);
  }, [matches]);

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Box className={classes.toolbarContent}>
          <Toolbar className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {menuSelected?.title || 'Painel de Controle'}
            </Typography>

            {/* Notification */}
            {/* <Notification /> */}

            {/* Button Profile */}
            <Button
              aria-describedby={anchorEl ? 'simple-popover' : undefined}
              onClick={handleClick}
              className={classes.buttonAvatar}
            >
              <Avatar className={clsx(classes.avatar)} />
              <Typography className={clsx(classes.profile)}>
                Olá, {user.name.split(' ')[0]}
              </Typography>
              <ExpandMoreIcon color="inherit" />
            </Button>
            <Popover
              id={anchorEl ? 'simple-popover' : undefined}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <List className={clsx(classes.listPopover)} onClick={handleClose}>
                <ListItem button component={Link} to="/admin/profile">
                  <ListItemText primary="Ver Perfil" />
                </ListItem>
                <Divider />
                <List>
                  <ListItem button onClick={handleSignOut}>
                    <ListItemText primary="Sair do Sistema" />
                  </ListItem>
                </List>
              </List>
            </Popover>
          </Toolbar>
        </Box>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={logoImg} alt="Logo" className={classes.toolbarImage} />
          <IconButton onClick={handleDrawerClose} color="secondary">
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {/* Menu */}
        <List>
          {!!primaryMenus.length &&
            primaryMenus.map((itemMenu) => {
              return (
                <ListItem
                  key={itemMenu.url}
                  button
                  component={Link}
                  to={itemMenu.url}
                  className={
                    menuSelected?.title === itemMenu.title
                      ? classes.menuSelected
                      : undefined
                  }
                >
                  <ListItemIcon>
                    <itemMenu.Icon color="inherit" />
                  </ListItemIcon>
                  <ListItemText primary={itemMenu.title} />
                  {menuSelected?.title === itemMenu.title && (
                    <span className={classes.menuBoxSelected} />
                  )}
                </ListItem>
              );
            })}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={handleSignOut}>
            <ListItemIcon>
              <PowerIcon color="inherit" />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
        <Divider />
        <List>
          {!!secondMenus.length &&
            secondMenus.map((itemMenu) => {
              return (
                <ListItem
                  key={itemMenu.url}
                  button
                  component={Link}
                  to={itemMenu.url}
                >
                  <ListItemIcon>
                    <itemMenu.Icon color="inherit" />
                  </ListItemIcon>
                  <ListItemText primary={itemMenu.title} />
                </ListItem>
              );
            })}
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>{children}</div>

          <Box pt={4}>
            <Footer version={appConfig.version} />
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default Default;
