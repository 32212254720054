import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import EmojiNatureIcon from '@material-ui/icons/EmojiNatureOutlined';
import FolderOpenIcon from '@material-ui/icons/FolderOpenOutlined';
import ForumIcon from '@material-ui/icons/ForumOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PaymentIcon from '@material-ui/icons/PaymentOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';

type Menu = {
  title: string;
  url: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
};

const primaryMenu: Menu[] = [
  {
    title: 'Painel de Controle',
    Icon: DashboardIcon,
    url: '/admin/dashboard',
  },
  {
    title: 'Pagamentos',
    Icon: PaymentIcon,
    url: '/admin/payments',
  },
  {
    title: 'Colônias',
    Icon: EmojiNatureIcon,
    url: '/admin/colonies',
  },
  {
    title: 'Suporte Técnico',
    Icon: AssignmentIcon,
    url: '/admin/technical-supports',
  },
  {
    title: 'Meliponários',
    Icon: FolderOpenIcon,
    url: '/admin/meliponarios',
  },
  {
    title: 'Usuários',
    Icon: PeopleIcon,
    url: '/admin/users',
  },
  {
    title: 'Mensagens',
    Icon: ForumIcon,
    url: '/admin/messages',
  },
];

const secondMenu: Menu[] = [
  {
    title: 'Suporte',
    Icon: InfoIcon,
    url: '/admin/support',
  },
  {
    title: 'Perfil',
    Icon: PersonIcon,
    url: '/admin/profile',
  },
];

const fullMenu: Menu[] = [...primaryMenu, ...secondMenu];

const menu = {
  getPrimaryMenus: () => {
    return primaryMenu;
  },
  getSecondMenus: () => {
    return secondMenu;
  },
  getSelected: (pathname: string) => {
    return fullMenu.find((item) => pathname.includes(item.url));
  },
};

export default menu;
